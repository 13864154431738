import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

export const IconExploreActive: React.FC<SvgIconProps> = (props: SvgIconProps): React.ReactElement<SvgIconProps> => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <path
      // eslint-disable-next-line max-len
      d="M23.2683 12.2404C21.7649 12.823 20.3818 13.7795 19.2203 15.0401C18.0588 16.3006 17.1482 17.8332 16.5552 19.5258C16.5094 19.6717 16.4251 19.7989 16.3143 19.8891C16.2035 19.9793 16.0719 20.0279 15.9384 20.0279C15.8049 20.0279 15.6763 19.9793 15.5712 19.8891C15.4661 19.7989 15.3898 19.6717 15.3533 19.5258C14.8964 17.8196 14.1106 16.2653 13.0528 14.9756C11.995 13.6859 10.6918 12.6931 9.23762 12.0691C9.11532 12.0196 9.01086 11.927 8.93876 11.8042C8.86666 11.6815 8.8305 11.5345 8.83526 11.3839C8.84002 11.2333 8.88546 11.0863 8.96532 10.9636C9.04518 10.8408 9.15549 10.7482 9.28092 10.6987C10.7849 10.1119 12.1683 9.15285 13.331 7.89103C14.4936 6.6292 15.4064 5.09623 16.0031 3.40323C16.0463 3.2559 16.1292 3.12676 16.2395 3.03495C16.3499 2.94314 16.4818 2.89355 16.6157 2.89355C16.7497 2.89355 16.8784 2.94314 16.983 3.03495C17.0875 3.12676 17.1623 3.2559 17.1962 3.40323C17.6492 5.11188 18.4336 6.66846 19.4918 7.95877C20.5501 9.24909 21.8554 10.2404 23.3119 10.8599C23.4374 10.9072 23.5452 10.9994 23.6198 11.1234C23.6944 11.2474 23.732 11.3968 23.7271 11.5502C23.7223 11.7035 23.6753 11.8529 23.5929 11.9769C23.5104 12.1009 23.3968 12.1932 23.2683 12.2404Z"
      fill="currentColor"
    />
    <path
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="M5.98682 13.4841C5.98682 12.8225 5.45046 12.2861 4.78884 12.2861C4.12721 12.2861 3.59085 12.8225 3.59085 13.4841V15.8857H1.19798C0.536355 15.8857 0 16.4221 0 17.0837C0 17.7454 0.536353 18.2817 1.19798 18.2817H3.59085V20.672C3.59085 21.3336 4.12721 21.87 4.78884 21.87C5.45046 21.87 5.98682 21.3336 5.98682 20.672V18.2817H8.38587C9.04749 18.2817 9.58385 17.7454 9.58385 17.0837C9.58385 16.4221 9.0475 15.8857 8.38587 15.8857H5.98682V13.4841Z"
      fill="currentColor"
      fillRule="evenodd"
    />
    <circle cx="7.28516" cy="5" fill="currentColor" r="3" />
  </SvgIcon>
);

export default IconExploreActive;
