import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

export const IconCareer: React.FC<SvgIconProps> = (props: SvgIconProps): React.ReactElement<SvgIconProps> => (
  <SvgIcon style={{ fill: 'none' }} viewBox="0 0 24 24" {...props}>
    <path
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="M12.9619 5.34674C13.52 5.04656 13.8994 4.45708 13.8994 3.77898C13.8994 2.79647 13.1029 2 12.1204 2C11.1379 2 10.3415 2.79647 10.3415 3.77898C10.3415 4.46364 10.7283 5.05798 11.2952 5.35538V9.65462H5.41803C5.15458 9.65462 4.90664 9.7792 4.7494 9.99058L3.4733 11.7061C3.25369 12.0013 3.25369 12.4056 3.4733 12.7008L4.7494 14.4163C4.90664 14.6277 5.15458 14.7523 5.41803 14.7523H11.2952V20.2438H8.30371C7.84347 20.2438 7.47038 20.6169 7.47038 21.0772C7.47038 21.5374 7.84347 21.9105 8.30371 21.9105H12.1286H15.9534C16.4137 21.9105 16.7868 21.5374 16.7868 21.0772C16.7868 20.6169 16.4137 20.2438 15.9534 20.2438H12.9619V11.3213H18.582C18.8454 11.3213 19.0933 11.1968 19.2506 10.9853L20.5267 9.26983C20.7463 8.97462 20.7463 8.57031 20.5267 8.27509L19.2506 6.55959C19.0933 6.34821 18.8454 6.22363 18.582 6.22363H12.9619V5.34674ZM12.9619 9.65462V7.8903H18.1633L18.8195 8.77246L18.1633 9.65462H12.9619ZM11.2952 11.3213H5.83675L5.18053 12.2034L5.83675 13.0856H11.2952V11.3213Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </SvgIcon>
);

export default IconCareer;
