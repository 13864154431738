/* Icon library: https://materialdesignicons.com/ */

import AlertBoxOutline from '@actinc/dls/icons/AlertBoxOutline';
import ArrowLeft from '@actinc/dls/icons/ArrowLeft';
import ArrowRight from '@actinc/dls/icons/ArrowRight';
import ChevronDown from '@actinc/dls/icons/ChevronDown';
import ChevronLeft from '@actinc/dls/icons/ChevronLeft';
import ChevronRight from '@actinc/dls/icons/ChevronRight';
import ChevronUp from '@actinc/dls/icons/ChevronUp';
import Close from '@actinc/dls/icons/Close';
import ExportVariant from '@actinc/dls/icons/ExportVariant';
import FilterOutline from '@actinc/dls/icons/FilterOutline';
import Heart from '@actinc/dls/icons/Heart';
import HeartOutline from '@actinc/dls/icons/HeartOutline';
import Help from '@actinc/dls/icons/Help';
import KeyboardTab from '@actinc/dls/icons/KeyboardTab';
import Laptop from '@actinc/dls/icons/Laptop';
import Magnify from '@actinc/dls/icons/Magnify';
import RadioboxBlank from '@actinc/dls/icons/RadioboxBlank';
import RadioboxMarked from '@actinc/dls/icons/RadioboxMarked';
import Sort from '@actinc/dls/icons/SwapVertical';
import { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

import IconAbout from '~/components/IconAbout';
import IconAboutActive from '~/components/IconAboutActive';
import IconCareer from '~/components/IconCareer';
import IconCareerActive from '~/components/IconCareerActive';
import IconCollege from '~/components/IconCollege';
import IconCollegeActive from '~/components/IconCollegeActive';
import IconExplore from '~/components/IconExplore';
import IconExploreActive from '~/components/IconExploreActive';
import IconFeedback from '~/components/IconFeedback';
import IconHighSchool from '~/components/IconHighSchool';
import IconHighSchoolActive from '~/components/IconHighSchoolActive';
import IconMatched from '~/components/IconMatched';
import IconMatchedActive from '~/components/IconMatchedActive';
import IconSave from '~/components/IconSave';
import IconSaveActive from '~/components/IconSaveActive';
import IconApplied from '~/public/static/img/icon-applied.svg';
import IconAwarded from '~/public/static/img/icon-awarded.svg';
import IconNotAwarded from '~/public/static/img/icon-not-awarded.svg';
import Apply from '~/public/static/img/journey_apply.svg';
import ListApply from '~/public/static/img/journey_apply_list_icon.svg';
import Basics from '~/public/static/img/journey_basics.svg';
import ListBasics from '~/public/static/img/journey_basics_list_icon.svg';
import Explore from '~/public/static/img/journey_explore.svg';
import ListExplore from '~/public/static/img/journey_explore_list_icon.svg';
import Finance from '~/public/static/img/journey_finance.svg';
import ListFinance from '~/public/static/img/journey_finance_list_icon.svg';
import Prepare from '~/public/static/img/journey_prepare.svg';
import ListPrepare from '~/public/static/img/journey_prepare_list_icon.svg';
import JourneyStatusCompleted from '~/public/static/img/journey_status_completed.svg';
import JourneyStatusInProgress from '~/public/static/img/journey_status_in_progress.svg';
import JourneyStatusNotApplicable from '~/public/static/img/journey_status_not_applicable.svg';
import JourneyStatusNotStarted from '~/public/static/img/journey_status_not_started.svg';
import Decide from '~/public/static/img/journey_transition.svg';
import ListDecide from '~/public/static/img/journey_transition_list_icon.svg';
import Majors from '~/public/static/img/majors-icon.svg';
import MyCollegesIcon from '~/public/static/img/my-colleges-icon.svg';
import MyScholarshipsIcon from '~/public/static/img/my-scholarship-icon.svg';

const getIcon = (selector: string): React.FC<SvgIconProps> => {
  const dlsIconMap: Map<string, React.FC<SvgIconProps> | unknown> = new Map([
    ['actions.close', Close],
    ['actions.save', HeartOutline],
    ['actions.search', Magnify],
    ['general.alert', AlertBoxOutline],
    ['general.filter', FilterOutline],
    ['general.share', ExportVariant],
    ['general.sort', Sort],
    ['general.laptop', Laptop],
    ['general.radio.blank', RadioboxBlank],
    ['general.radio.marked', RadioboxMarked],
    ['general.saved', Heart],
    ['general.arrow.down', ChevronDown],
    ['general.arrow.left', ArrowLeft],
    ['general.arrow.right', ArrowRight],
    ['general.arrow.up', ChevronUp],
    ['general.chevron.left', ChevronLeft],
    ['general.chevron.right', ChevronRight],
    ['general.keyboard.tab', KeyboardTab],
  ]);

  const customIconMap: Map<string, React.FC<SvgIconProps> | unknown> = new Map([
    ['general.about', IconAbout],
    ['general.about.active', IconAboutActive],
    ['general.career', IconCareer],
    ['general.career.active', IconCareerActive],
    ['general.college', IconCollege],
    ['general.college.active', IconCollegeActive],
    ['general.explore', IconExplore],
    ['general.explore.active', IconExploreActive],
    ['general.feedback', IconFeedback],
    ['general.highSchool', IconHighSchool],
    ['general.highSchool.active', IconHighSchoolActive],
    ['general.matched', IconMatched],
    ['general.matched.active', IconMatchedActive],
    ['general.save', IconSave],
    ['general.save.active', IconSaveActive],
  ]);

  const staticIconMap: Map<string, React.FC<SvgIconProps> | unknown> = new Map([
    ['general.major', Majors],
    ['general.my.colleges', MyCollegesIcon],
    ['general.my.scholarships', MyScholarshipsIcon],
    ['journey.apply', Apply],
    ['journey.basics', Basics],
    ['journey.discover', Explore],
    ['journey.finance', Finance],
    ['journey.prepare', Prepare],
    ['journey.transition', Decide],
    ['journey.list.apply', ListApply],
    ['journey.list.basics', ListBasics],
    ['journey.list.discover', ListExplore],
    ['journey.list.finance', ListFinance],
    ['journey.list.prepare', ListPrepare],
    ['journey.list.transition', ListDecide],
    ['journey.status.not.applicable', JourneyStatusNotApplicable],
    ['journey.status.not.started', JourneyStatusNotStarted],
    ['journey.status.progress', JourneyStatusInProgress],
    ['journey.status.completed', JourneyStatusCompleted],
    ['scholarship.applied', IconApplied],
    ['scholarship.not.awarded', IconNotAwarded],
    ['scholarship.awarded', IconAwarded],
  ]);

  return (dlsIconMap.get(selector) ?? customIconMap.get(selector) ?? staticIconMap.get(selector) ?? Help) as React.FC<SvgIconProps>;
};

export default getIcon;
