import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

export const IconCareerActive: React.FC<SvgIconProps> = (props: SvgIconProps): React.ReactElement<SvgIconProps> => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <path
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="M12.9961 5.36179C13.574 5.06025 13.9668 4.46812 13.9668 3.78697C13.9668 2.80005 13.1421 2 12.1247 2C11.1073 2 10.2826 2.80005 10.2826 3.78697C10.2826 4.47472 10.6831 5.07172 11.2702 5.37047V9.68903H5.18434C4.91152 9.68903 4.65479 9.81417 4.49196 10.0265L3.17055 11.7497C2.94315 12.0463 2.94315 12.4524 3.17055 12.7489L4.49196 14.4721C4.65479 14.6844 4.91152 14.8096 5.18434 14.8096H11.2702V20.3258H8.17247C7.69589 20.3258 7.30955 20.7006 7.30955 21.1629C7.30955 21.6252 7.69589 22 8.17247 22H12.1331H16.0938C16.5704 22 16.9567 21.6252 16.9567 21.1629C16.9567 20.7006 16.5704 20.3258 16.0938 20.3258H12.9961V11.3632H18.8157C19.0885 11.3632 19.3452 11.2381 19.508 11.0257L20.8294 9.30251C21.0569 9.00597 21.0569 8.59984 20.8294 8.3033L19.508 6.58009C19.3452 6.36775 19.0885 6.24262 18.8157 6.24262H12.9961V5.36179ZM12.9961 9.68903V7.91678H18.3821L19.0616 8.8029L18.3821 9.68903H12.9961ZM11.2702 11.3632H5.61792L4.9384 12.2493L5.61792 13.1354H11.2702V11.3632Z"
      fill="currentColor"
      fillRule="evenodd"
    />
    <rect fill="currentColor" height="2.76236" width="6.9786" x="12.626" y="7.44531" />
    <rect fill="currentColor" height="2.76236" width="6.9786" x="4.77539" y="11.1279" />
  </SvgIcon>
);

export default IconCareerActive;
