import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

export const IconExplore: React.FC<SvgIconProps> = (props: SvgIconProps): React.ReactElement<SvgIconProps> => (
  <SvgIcon style={{ fill: 'none' }} viewBox="0 0 24 24" {...props}>
    <path
      // eslint-disable-next-line max-len
      d="M18.7089 8.60766C19.7336 9.85699 20.9788 10.8574 22.3749 11.5443C20.9324 12.1957 19.6138 13.1605 18.4931 14.3768C17.4663 15.4911 16.6253 16.7948 16.0042 18.2208C15.4988 16.7848 14.7658 15.4651 13.8337 14.3286C12.8114 13.0822 11.5707 12.0821 10.1799 11.3921C11.62 10.7373 12.9363 9.77168 14.0562 8.55624C15.0811 7.44401 15.922 6.14393 16.5451 4.72217C17.0479 6.15588 17.7787 7.47345 18.7089 8.60766Z"
      stroke="currentColor"
      strokeWidth="2"
    />
    <path
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="M5.9873 13.4851C5.9873 12.8235 5.45095 12.2871 4.78932 12.2871C4.1277 12.2871 3.59134 12.8235 3.59134 13.4851V15.8867H1.19798C0.536355 15.8867 0 16.4231 0 17.0847C0 17.7463 0.536353 18.2827 1.19798 18.2827H3.59134V20.673C3.59134 21.3346 4.1277 21.871 4.78932 21.871C5.45095 21.871 5.9873 21.3346 5.9873 20.673V18.2827H8.38587C9.04749 18.2827 9.58385 17.7463 9.58385 17.0847C9.58385 16.4231 9.0475 15.8867 8.38587 15.8867H5.9873V13.4851Z"
      fill="currentColor"
      fillRule="evenodd"
    />
    <circle cx="7.28613" cy="5" r="2" stroke="currentColor" strokeWidth="2" />
  </SvgIcon>
);

export default IconExplore;
