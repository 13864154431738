import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

export const IconCollegeActive: React.FC<SvgIconProps> = (props: SvgIconProps): React.ReactElement<SvgIconProps> => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <path
      // eslint-disable-next-line max-len
      d="M20.05 9.61876C20.1748 9.61879 20.2983 9.59418 20.4136 9.54633C20.5289 9.49848 20.6336 9.42832 20.7218 9.33988C20.81 9.25143 20.88 9.14643 20.9278 9.03086C20.9755 8.9153 21 8.79144 21 8.66636V5.80915C21.0001 5.60931 20.9374 5.41452 20.8208 5.25241C20.7043 5.09031 20.5397 4.96912 20.3506 4.90604L11.8006 2.04883C11.6055 1.98372 11.3945 1.98372 11.1994 2.04883L2.64941 4.90604C2.46027 4.96912 2.29574 5.09031 2.17918 5.25241C2.06261 5.41452 1.99992 5.60931 2 5.80915V8.66636C1.99997 8.79144 2.02452 8.9153 2.07225 9.03086C2.11998 9.14643 2.18995 9.25143 2.27818 9.33988C2.3664 9.42832 2.47114 9.49848 2.58641 9.54633C2.70169 9.59418 2.82524 9.61879 2.95 9.61876H3.9V16.4611C3.3459 16.6568 2.8658 17.0196 2.5256 17.4997C2.18541 17.9799 2.00181 18.5538 2 19.1428V21.0476C1.99997 21.1727 2.02452 21.2965 2.07225 21.4121C2.11998 21.5277 2.18995 21.6327 2.27818 21.7211C2.3664 21.8096 2.47114 21.8797 2.58641 21.9276C2.70169 21.9754 2.82524 22 2.95 22H20.05C20.1748 22 20.2983 21.9754 20.4136 21.9276C20.5289 21.8797 20.6336 21.8096 20.7218 21.7211C20.81 21.6327 20.88 21.5277 20.9278 21.4121C20.9755 21.2965 21 21.1727 21 21.0476V19.1428C20.9982 18.5538 20.8146 17.9799 20.4744 17.4997C20.1342 17.0196 19.6541 16.6568 19.1 16.4611V9.61876H20.05ZM19.1 20.0952H3.9V19.1428C3.90025 18.8903 4.00042 18.6482 4.17853 18.4696C4.35663 18.2911 4.59812 18.1906 4.85 18.1904H18.15C18.4019 18.1906 18.6434 18.2911 18.8215 18.4696C18.9996 18.6482 19.0997 18.8903 19.1 19.1428V20.0952ZM5.8 16.2856V9.61876H7.7V16.2856H5.8ZM9.6 16.2856V9.61876H13.4V16.2856H9.6ZM15.3 16.2856V9.61876H17.2V16.2856H15.3ZM3.9 7.71395V6.49554L11.5 3.95548L19.1 6.49554V7.71395H3.9Z"
      fill="currentColor"
    />
    <path d="M2.42969 5.80936L11.4775 2.95215L20.5253 5.80936V8.66657H2.42969V5.80936Z" fill="currentColor" />
    <path d="M2.42969 18.1907L11.4775 17.2383L20.5253 18.1907V21.0479H2.42969V18.1907Z" fill="currentColor" />
  </SvgIcon>
);

export default IconCollegeActive;
