import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

export const IconHighSchoolActive: React.FC<SvgIconProps> = (props: SvgIconProps): React.ReactElement<SvgIconProps> => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <path
      // eslint-disable-next-line max-len
      d="M19.8464 14.8985L19.8434 14.9149C19.545 16.6339 18.9573 18.0249 18.1104 19.124L18.1071 19.1284C17.1255 20.417 15.9637 21 14.5686 21C14.3474 21 14.0452 20.9336 13.6449 20.7321C13.1419 20.439 12.5864 20.2969 12 20.2969C11.4132 20.2969 10.8582 20.4391 10.3552 20.732C9.95488 20.9335 9.65262 21 9.43142 21C8.03634 21 6.87454 20.417 5.89291 19.1284L5.89292 19.1284L5.88958 19.1241C5.04272 18.0249 4.45504 16.6339 4.15655 14.9149L4.15356 14.8985C3.96204 13.895 3.94953 12.896 4.1168 11.8952C4.31046 10.7888 4.66363 9.92676 5.14194 9.27012C5.66803 8.55399 6.30136 8.16094 7.06904 8.02521C7.50734 7.95546 8.22698 8.00754 9.2975 8.27972C10.2036 8.5253 10.9399 8.81144 11.5212 9.12814C11.8194 9.29062 12.1798 9.29062 12.478 9.12814C13.0593 8.81144 13.7956 8.52531 14.7017 8.27973C15.7726 8.00755 16.4926 7.95546 16.931 8.02521C17.6986 8.16094 18.332 8.55399 18.8581 9.27012C19.3364 9.92678 19.6896 10.7888 19.8832 11.8953C20.0505 12.8961 20.038 13.895 19.8464 14.8985ZM13.1584 5.21047C13.1435 5.21425 13.1287 5.21838 13.114 5.22284C12.8908 5.29064 12.6456 5.33746 12.3756 5.35988C12.3801 5.30788 12.3854 5.25446 12.3917 5.19962C12.4801 4.42649 12.7161 3.95568 13.0056 3.66682C13.2315 3.4673 13.5647 3.28836 14.0522 3.16453C14.0671 3.16075 14.0819 3.15662 14.0966 3.15216C14.3197 3.0844 14.5647 3.03759 14.8345 3.01516C14.811 3.26043 14.765 3.48369 14.6998 3.68738C14.6949 3.70267 14.6903 3.71808 14.6862 3.73359C14.5643 4.18881 14.3894 4.50295 14.1915 4.71998C13.9659 4.91475 13.6363 5.08908 13.1584 5.21047Z"
      fill="currentColor"
      stroke="currentColor"
      strokeLinejoin="round"
      strokeWidth="2"
    />
  </SvgIcon>
);

export default IconHighSchoolActive;
