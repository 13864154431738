import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

export const IconSaveActive: React.FC<SvgIconProps> = (props: SvgIconProps): React.ReactElement<SvgIconProps> => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <path
      // eslint-disable-next-line max-len
      d="M14.0753 20.1307C13.3153 20.8207 12.1453 20.8207 11.3853 20.1207L11.2753 20.0207C6.02525 15.2707 2.59525 12.1607 2.72525 8.28065C2.78525 6.58065 3.65525 4.95065 5.06525 3.99065C7.70525 2.19065 10.9653 3.03065 12.7253 5.09065C14.4853 3.03065 17.7452 2.18065 20.3853 3.99065C21.7952 4.95065 22.6653 6.58065 22.7253 8.28065C22.8652 12.1607 19.4253 15.2707 14.1753 20.0407L14.0753 20.1307Z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default IconSaveActive;
