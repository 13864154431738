import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

export const IconFeedback: React.FC<SvgIconProps> = (props: SvgIconProps): React.ReactElement<SvgIconProps> => (
  <SvgIcon style={{ fill: 'none' }} viewBox="0 0 24 24" {...props}>
    <g clipPath="url(#clip0_215_9790)">
      <path d="M20 2H4C2.9 2 2 2.9 2 4V22L6 18H20C21.1 18 22 17.1 22 16V4C22 2.9 21.1 2 20 2ZM20 16H6L4 18V4H20V16Z" fill="currentColor" />
    </g>
    <defs>
      <clipPath id="clip0_215_9790">
        <rect fill="transparent" height="24" width="24" />
      </clipPath>
    </defs>
  </SvgIcon>
);

export default IconFeedback;
