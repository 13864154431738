import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

export const IconCollege: React.FC<SvgIconProps> = (props: SvgIconProps): React.ReactElement<SvgIconProps> => (
  <SvgIcon style={{ fill: 'none' }} viewBox="0 0 24 24" {...props}>
    <path
      // eslint-disable-next-line max-len
      d="M20.2083 9.58319C20.3342 9.58322 20.4588 9.55846 20.5751 9.51031C20.6914 9.46216 20.7971 9.39157 20.8861 9.30257C20.975 9.21358 21.0456 9.10792 21.0938 8.99163C21.1419 8.87535 21.1667 8.75072 21.1667 8.62486V5.74986C21.1668 5.54877 21.1035 5.35277 20.9859 5.18966C20.8683 5.02654 20.7024 4.9046 20.5116 4.84113L11.8866 1.96613C11.6897 1.90061 11.477 1.90061 11.2801 1.96613L2.65511 4.84113C2.46431 4.9046 2.29834 5.02654 2.18075 5.18966C2.06316 5.35277 1.99992 5.54877 2 5.74986V8.62486C1.99997 8.75072 2.02473 8.87535 2.07288 8.99163C2.12103 9.10792 2.19162 9.21358 2.28062 9.30257C2.36961 9.39157 2.47527 9.46216 2.59156 9.51031C2.70784 9.55846 2.83247 9.58322 2.95833 9.58319H3.91667V16.4681C3.35771 16.665 2.87339 17.0301 2.53021 17.5132C2.18704 17.9964 2.00182 18.5739 2 19.1665V21.0832C1.99997 21.2091 2.02473 21.3337 2.07288 21.45C2.12103 21.5663 2.19162 21.6719 2.28062 21.7609C2.36961 21.8499 2.47527 21.9205 2.59156 21.9686C2.70784 22.0168 2.83247 22.0416 2.95833 22.0415H20.2083C20.3342 22.0416 20.4588 22.0168 20.5751 21.9686C20.6914 21.9205 20.7971 21.8499 20.8861 21.7609C20.975 21.6719 21.0456 21.5663 21.0938 21.45C21.1419 21.3337 21.1667 21.2091 21.1667 21.0832V19.1665C21.1648 18.5739 20.9796 17.9964 20.6365 17.5132C20.2933 17.0301 19.809 16.665 19.25 16.4681V9.58319H20.2083ZM19.25 20.1249H3.91667V19.1665C3.91692 18.9124 4.01797 18.6688 4.19764 18.4892C4.3773 18.3095 4.62091 18.2084 4.875 18.2082H18.2917C18.5458 18.2084 18.7894 18.3095 18.969 18.4892C19.1487 18.6688 19.2497 18.9124 19.25 19.1665V20.1249ZM5.83333 16.2915V9.58319H7.75V16.2915H5.83333ZM9.66667 16.2915V9.58319H13.5V16.2915H9.66667ZM15.4167 16.2915V9.58319H17.3333V16.2915H15.4167ZM3.91667 7.66652V6.44053L11.5833 3.88465L19.25 6.44053V7.66652H3.91667Z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default IconCollege;
