import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

export const IconAboutActive: React.FC<SvgIconProps> = (props: SvgIconProps): React.ReactElement<SvgIconProps> => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <circle cx="11.5715" cy="6.7619" fill="currentColor" r="4.7619" />
    <path
      // eslint-disable-next-line max-len
      d="M3 19.3809C3 16.6194 5.23858 14.3809 8 14.3809H16.0952C18.8567 14.3809 21.0952 16.6194 21.0952 19.3809V20.9999C21.0952 21.5522 20.6475 21.9999 20.0952 21.9999H4C3.44772 21.9999 3 21.5522 3 20.9999V19.3809Z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default IconAboutActive;
