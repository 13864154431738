import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

export const IconMatchedActive: React.FC<SvgIconProps> = (props: SvgIconProps): React.ReactElement<SvgIconProps> => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <rect
      fill="currentColor"
      height="16.8259"
      rx="2"
      stroke="white"
      strokeWidth="2"
      transform="rotate(-15 0.775255 4.78703)"
      width="11.6368"
      x="0.775255"
      y="4.78703"
    />
    <rect
      fill="currentColor"
      height="17.3647"
      rx="2"
      stroke="white"
      strokeWidth="2"
      transform="rotate(15 11.6464 3.46861)"
      width="11.9871"
      x="11.6464"
      y="3.46861"
    />
  </SvgIcon>
);

export default IconMatchedActive;
