import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

export const IconSave: React.FC<SvgIconProps> = (props: SvgIconProps): React.ReactElement<SvgIconProps> => (
  <SvgIcon style={{ fill: 'none' }} viewBox="0 0 24 24" {...props}>
    <path
      // eslint-disable-next-line max-len
      d="M13.4024 19.3873L13.3992 19.3903C13.0211 19.7335 12.4367 19.7332 12.0588 19.3851L12.0588 19.3851L12.054 19.3807L11.944 19.2807L11.9423 19.2791C9.29632 16.8852 7.19444 14.9781 5.75715 13.1965C4.33443 11.433 3.66736 9.91644 3.72075 8.315C3.77019 6.9226 4.48657 5.59177 5.62414 4.81725L5.62468 4.81688C7.78074 3.34684 10.4812 4.00809 11.961 5.74023L12.7213 6.63012L13.4816 5.74023C14.9636 4.00566 17.6629 3.33934 19.8159 4.81543L19.8186 4.81725C20.9564 5.59194 21.6728 6.92319 21.722 8.31593L21.722 8.31671C21.7797 9.91666 21.1138 11.4323 19.6891 13.1992C18.2507 14.9832 16.1474 16.8942 13.5022 19.2975L13.4989 19.3005L13.4024 19.3873Z"
      stroke="currentColor"
      strokeWidth="2"
    />
  </SvgIcon>
);

export default IconSave;
